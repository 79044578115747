import { createApp, nextTick } from "vue";
import router from "@/router";
import App from "./App.vue";

import { plugin as Slicksort } from "vue-slicksort";

import { initializeApp } from "firebase/app";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import VueSimpleContextMenu from "vue-simple-context-menu";

import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";

import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";

import ElementPlus from "element-plus";
import uk from "element-plus/dist/locale/uk.mjs";
import "element-plus/dist/index.css";

import VueTelInput from "vue-tel-input";
import "@/styles/vue-tel-input.css";

import VueMask from "@devindex/vue-mask";

import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

import VueApexCharts from "vue3-apexcharts";

import "/node_modules/flag-icons/css/flag-icons.min.css";

import VueTheMask from 'vue-the-mask'


router.beforeEach((to, from, next) => {
  if (
    window.origin.indexOf("ceoclubcrm") >= 0
  ) {
    window.location.replace("https://crm.ceoclub.com.ua");
  }
  
  if (
    to.name !== "LogInPage" &&
    to.name !== "ResetPasswordPage" &&
    to.name !== "GoToAppPage" &&
    to.name !== "GoToAppSecondPage" &&
    to.name !== "GoToAppThirdPage" &&
    to.name !== "RegistrationManagerPage" &&
    !localStorage.webSessionId
  ) {
    next({ name: "LogInPage" });
  } else {
    nextTick().then(() => {
      next();
    });
  }
});

const firebaseConfig = {
  apiKey: "AIzaSyDY7YOQ3gmuiZbmiRKbeYf7vRIG_WtU8ow",
  authDomain: "ceoclubapi.firebaseapp.com",
  databaseURL:
    "https://ceoclubapi-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ceoclubapi",
  storageBucket: "ceoclubapi.appspot.com",
  messagingSenderId: "397464085949",
  appId: "1:397464085949:web:9eca6962f0edb8063b1ed4",
  measurementId: "G-TNY8MJK7WW",
};

// eslint-disable-next-line
const firebaseApp = initializeApp(firebaseConfig);
// const auth = getAuth(firebaseApp);
// // eslint-disable-next-line
// const client = signInWithEmailAndPassword(
//   auth,
//   "sivachenkovlad@gmail.com",
//   "!Qqazqaz21"
// );

const app = createApp(App);

app.config.globalProperties.$api_url =
  "https://ceoclubapi-default-rtdb.europe-west1.firebasedatabase.app";
app.directive("click-outside", {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      // Check if the clicked element is part of the calendar
      // Here I check that click was outside the el and his children and not part of the calendar
      if (
        !(el == event.target || el.contains(event.target)) &&
        !event.target.classList.contains("day-item") &&
        !event.target.classList.contains("button-next-month") &&
        !event.target.classList.contains("button-previous-month")
      ) {
        // And if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
});

if (
  window.origin.indexOf("ceoclubcrm") >= 0
) {
  window.location.replace("https://crm.ceoclub.com.ua");
}

app.use(router);
app.use(Slicksort);
app.use(VCalendar, {});
app.use(VueTelInput);
app.use(Vue3SimpleHtml2pdf);
app.use(VueTelInput);
app.use(VueApexCharts);
app.use(VueTheMask);
app.component("QuillEditor", QuillEditor);
app.use(VueTippy, {
  directive: "tippy", // => v-tippy
  component: "tippy", // => <tippy/>
});
app.use(VueMask);
app.component("vue-simple-context-menu", VueSimpleContextMenu);
app.use(ElementPlus, {
  locale: uk,
});
app.mount("#app");
